<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
      <!-- 合作伙伴 -->
      <ServicePartner />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";

export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc10_0.jpg'),
        //   title: '工程广告',
        //   intro: '专业团队，快速出稿'
        // },
      ],
      info: {
        title: '工程广告',
        desc: '通过一定形式的媒体，公开而广泛地向公众传递信息的宣传手段。它包括电视广告、广播广告、促销推广、平面广告等，是一种非常广泛的广告形式。既要提高工程品牌的知名度和美誉度，也要吸引潜在客户的关注和兴趣，并增强品牌竞争力。在工程广告中，采用各种广告形式和手段，如海报、宣传册、网站、等，以吸引潜在客户的关注和兴趣。',
        list: [
          { id: 1, title: '确认广告<br>主图' },
          { id: 2, title: '设计广告<br>内容' },
          { id: 3, title: '选择广告<br>媒介' },
          { id: 4, title: '发布广告' },
          { id: 5, title: '收集反馈' },
          { id: 6, title: '评估效果<br>数据' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc10_1.jpg'),
          tit: '专业的广告团队',
          desc: '团队由丰富的工程广告策划和执行经验组成，提供高质量的广告宣传方案。'
        }, {
          id: 2,
          img: require('@/assets/images/svc10_2.jpg'),
          tit: '多元化广告形式',
          desc: '拥有广泛的媒体资源和网络渠道，能够将工程项目宣传到更广泛的受众中。'
        }, {
          id: 3,
          img: require('@/assets/images/svc10_3.jpg'),
          tit: '个性化的广告方案',
          desc: '根据工程项目特点和需求，量身定制个性化的广告方案。'
        }, {
          id: 4,
          img: require('@/assets/images/svc10_4.jpg'),
          tit: '优秀的客户体验',
          desc: '提供全方位的客户服务，享受到最优质的服务体验。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>